import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider } from "@mantine/core";

Sentry.init({
  dsn: "https://7d6164ecf7734435ab997b67f69d7e4a@o1137090.ingest.sentry.io/6212954",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
