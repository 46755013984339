import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { Dropzone } from "@mantine/dropzone";
import {
  Group,
  Text,
  useMantineTheme,
  Button,
  Input,
  InputWrapper,
  ColorPicker,
  List,
  ThemeIcon,
  Checkbox,
} from "@mantine/core";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUpload, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useClipboard } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";
import { Prism } from "@mantine/prism";

// TODO: Cleanup this mess
function App() {
  const theme = useMantineTheme();
  theme.colorScheme = "dark";

  const clipboard = useClipboard();
  const notifications = useNotifications();

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [dropZoneLoading, setDropZoneLoading] = useState(false);

  const [color, onColorChange] = useState("#ffffff");
  const [shareXColor, onShareXColorChange] = useState("#ffffff");
  const [vibrant, setVibrant] = useState(false);
  const [result, setResult] = useState("");

  const domainList = [
    "sussybaka.pro",
    "marvel-official.com",
    "kokainkaufen24.de",
  ];
  const domain = randomChoiceFromList(domainList);

  const Title = document.querySelector("#Title") as HTMLInputElement;
  const Description = document.querySelector(
    "#Description"
  ) as HTMLInputElement;
  const Domain = document.querySelector("#Domain") as HTMLInputElement;

  const [title, setTitle] = useState(Title?.value);
  const [description, setDescription] = useState(Description?.value);
  const [url, setURL] = useState(Domain?.value);

  const shareXTitle = document.querySelector(
    "#shareXTitle"
  ) as HTMLInputElement;
  const shareXDescription = document.querySelector(
    "#shareXDescription"
  ) as HTMLInputElement;
  const shareXDomain = document.querySelector(
    "#shareXDomain"
  ) as HTMLInputElement;

  function randomChoiceFromList(list: string[]) {
    return list[Math.floor(Math.random() * list.length)];
  }

  const [ShareXTitle, setShareXTitle] = useState(shareXTitle?.value || "");
  const [ShareXDescription, setShareXDescription] = useState(
    shareXDescription?.value || ""
  );
  const [ShareXDomain, setShareXDomain] = useState(domain);

  let shareXConfig = `{
    "Version": "13.7.0",
    "DestinationType": "ImageUploader, TextUploader, FileUploader",
    "RequestMethod": "POST",
    "RequestURL": "https://stackcdn.flux-industries.workers.dev/upload",
    "Body": "MultipartFormData",
    "Arguments": {
      "title": "${ShareXTitle}",
      "description": "${ShareXDescription}",
      "color": "${shareXColor}",
      "domain": "${ShareXDomain}"
    },
    "FileFormName": "file",
    "URL": "$json:url$"
  }`;

  function uploadFile() {
    if (fileToUpload) {
      const currentDomain = Domain?.value;
      const currentTitle = Title?.value || "";
      const currentDescription = Description?.value || "";
      setDropZoneLoading(true);
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append("title", currentTitle);
      formData.append("description", currentDescription);
      formData.append("color", color);
      formData.append("domain", currentDomain);
      const notification = notifications.showNotification({
        title: "Uploading...",
        message: "Uploading file to StackCDN",
        color: "blue",
        autoClose: false,
      });
      fetch(
        "https://cors.flux.industries/?https://stackcdn.flux-industries.workers.dev/upload",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setResult(data.url);
          setDropZoneLoading(false);
          notifications.updateNotification(notification, {
            title: "Uploaded!",
            message: "File uploaded to StackCDN",
            color: "green",
            autoClose: 5000,
          });
        });
    } else {
      notifications.showNotification({
        title: "Error!",
        message: "Please select a file to upload.",
        color: "red",
        autoClose: false,
      });
    }
  }

  return (
    <div className="grid h-full bg-[#25262B] text-white justify-center">
      <div className="grid">
        <div className="grid">
          <div className="grid grid-cols-2">
            <h1 className="font-bold text-8xl mt-8 text-center place-self-center">
              StackCDN
            </h1>

            <List
              className="place-self-center mt-12"
              spacing="xs"
              size="sm"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <FontAwesomeIcon icon={faCheck} />
                </ThemeIcon>
              }
            >
              <List.Item>No registration</List.Item>
              <List.Item>No ratelimiting</List.Item>
              <List.Item>No captchas</List.Item>
              <List.Item>No ads</List.Item>
              <List.Item>No storage limit</List.Item>
            </List>
          </div>

          <div className="bg-[#1A1B1E] p-8 mt-12 shadow-lg drop-shadow-lg rounded grid">
            <Dropzone
              onDrop={(files) => {
                setDropZoneLoading(true);
                setFileToUpload(files[0]);
                setDropZoneLoading(false);
              }}
              onReject={(files) => console.log("rejected files", files)}
              multiple={false}
              loading={dropZoneLoading}
            >
              {(status) => (
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: 220, pointerEvents: "none" }}
                >
                  <FontAwesomeIcon icon={faUpload} size="4x" />

                  <div>
                    <Text size="xl" inline>
                      Drag and drop images or files to get started
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      Selected file: {fileToUpload ? fileToUpload.name : "none"}
                    </Text>
                  </div>
                </Group>
              )}
            </Dropzone>

            <InputWrapper
              label="Upload Settings"
              className="mt-4 text-center grid"
            >
              {/* <Text className="text-left">URL Location</Text>
              <Input
                placeholder="URL Location"
                className="mb-4"
                defaultValue={uuid()}
              /> */}

              <Text className="text-left">Title</Text>
              <Input
                placeholder="Title"
                className="mb-4"
                id="Title"
                onInput={() => setTitle(Title?.value)}
              />

              <Text className="text-left">Description</Text>
              <Input
                placeholder="Description"
                className="mb-4"
                id="Description"
                onInput={() => setDescription(Description?.value)}
              />

              <Text className="text-left">Domain</Text>
              <Input
                placeholder="Domain"
                className="mb-4"
                defaultValue={domain}
                id="Domain"
                onInput={() => setURL(Domain?.value)}
              />

              <Text className="text-left">Color</Text>
              {!vibrant ? (
                <Group position="center" direction="column" className="mb-4">
                  <ColorPicker
                    format="hex"
                    value={color}
                    onChange={onColorChange}
                  />
                  <Text>{color}</Text>
                </Group>
              ) : (
                <br />
              )}

              {/* <Checkbox
                className="place-self-center mb-6"
                checked={vibrant}
                onChange={(event) => setVibrant(event.currentTarget.checked)}
                label="Vibrant (only images)"
              ></Checkbox> */}
            </InputWrapper>

            <Button
              className="bg-blue-600 place-self-center mb-4"
              onClick={uploadFile}
            >
              Upload
            </Button>

            <Text className="text-left">Result URL</Text>
            <Input
              disabled={true}
              placeholder="Result"
              value={result}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(result);
                    notifications.showNotification({
                      title: "Result URL",
                      message: "Copied to clibpoard.",
                      color: "blue",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </div>

          <div className="grid place-items-center my-12 p-8 bg-[#1A1B1E]">
            <h1 className="font-bold text-2xl">About</h1>
            <h2 className="text-xl font-bold mt-4">Custom Domains</h2>
            <p>
              If you would like to use this service with your own domain do the
              following:
            </p>
            <ol className="list-decimal">
              <li>
                Put your domain on{" "}
                <a
                  href="https://cloudflare.com"
                  className="underline text-orange-400"
                >
                  Cloudflare
                </a>
              </li>
              <li>
                Proxy the (sub-)domain to{" "}
                <span className="font-bold">192.0.2.1</span>
              </li>
              <li>
                Create a Page Rule which looks as the following:{" "}
                <ul>
                  <li>
                    URL match:{" "}
                    <span className="font-bold">i.example.com/*</span>
                  </li>
                  <li>
                    Forwarding URL:{" "}
                    <span className="font-bold">301 - Permanent Redirect</span>
                  </li>
                  <li>
                    Destination URL:{" "}
                    <span className="font-bold">
                      https://stackcdn.flux-industries.workers.dev/$1
                    </span>
                  </li>
                </ul>
              </li>
              <li>Done!</li>
            </ol>

            <h2 className="text-xl font-bold mt-4">Our Domains</h2>
            <p>We also have some domains you could use with our service:</p>
            <ul className="font-bold">
              <li>*.kokainkaufen24.de</li>
              <li>*.marvel-official.com</li>
              <li>*.sussybaka.pro</li>
              <li>cdn.flux.industries</li>
            </ul>

            <h2 className="text-xl font-bold mt-4">Donation</h2>
            <p>
              If you really like our service and want to support us, you can
              donate crypto currencies at the following addresses:
            </p>
            <ul>
              <li>
                <span className="font-bold">Bitcoin: </span>
                bc1qcu0aqrzsjxj0lhjskltudheq2dyfhguzes9rqt
              </li>
              <li>
                <span className="font-bold">Ethereum: </span>
                0x887AF199537f56992387af30d9C8b468876D31Fd
              </li>
              <li>
                <span className="font-bold">Litecoin: </span>
                LhZGV9CAudZzdrMYXVi2sYiMXkK5U2hUjd
              </li>
              <li>
                <span className="font-bold">Bitcoin Cash: </span>
                qpj8my02er6q9t6u98mjruv8xh39p327s5ktrfs78m
              </li>
              <li>
                <span className="font-bold">Dogecoin: </span>
                DKKURXWvzxjn3qnfypMreRAJFvLMSMQ1G8
              </li>
              <li>
                <span className="font-bold">Monero: </span>
                46AybH7w4yHTfxUCQtuxa8faSeBKhSzydTtuwkKaLA4RfwMYnEbv9dUeNGzaButwSTaXnEoHCwmVCXXmSxvpyST52mZtQKf
              </li>
              <li>
                <span className="font-bold">Solana: </span>
                8SP1V75KFBGRg5reYvejBfaLQpHtyMiX6hiagojiyQDZ
              </li>
              <li>
                <span className="font-bold">Cardano: </span>
                addr1qyc2jcy8t54leya85kw95ffxfqu4xyjfw64xwyktdr9p68e3k4ussekyxdzlw52smqa2tnwq3hwt4cdy4psrzmm3lpys394v3z
              </li>
              <li>
                <span className="font-bold">Stellar: </span>
                GDW6NTYIYZ6APUZRRVXVELICEK6ITRBZDPISLWBNLXSVQ43MHYB3Z76M
              </li>
            </ul>
          </div>

          <div className="grid place-items-center my-4 p-8 bg-[#1A1B1E]">
            <h1 className="font-bold text-2xl">ShareX</h1>
            <InputWrapper
              label="Config Generator"
              className="mt-4 text-center grid min-w-full"
            >
              {/* <Text className="text-left">URL Location (Will use zero width space characters)</Text>
              <Input
                placeholder="URL Location (Not used at the moment)"
                className="mb-4"
                disabled={true}
              /> */}

              <Text className="text-left">Title</Text>
              <Input
                placeholder="Title"
                className="mb-4"
                id="shareXTitle"
                onInput={() => setShareXTitle(shareXTitle?.value)}
              />

              <Text className="text-left">Description</Text>
              <Input
                placeholder="Description"
                className="mb-4"
                id="shareXDescription"
                onInput={() => setShareXDescription(shareXDescription?.value)}
              />

              <Text className="text-left">Domain</Text>
              <Input
                placeholder="Domain"
                className="mb-4"
                defaultValue={domain}
                id="shareXDomain"
                onInput={() => setShareXDomain(shareXDomain?.value)}
              />

              <Text className="text-left">Color</Text>
              {!vibrant ? (
                <Group position="center" direction="column" className="mb-4">
                  <ColorPicker
                    format="hex"
                    value={shareXColor}
                    onChange={onShareXColorChange}
                  />
                  <Text>{shareXColor}</Text>
                </Group>
              ) : (
                <br />
              )}

              {/* <Checkbox
                className="place-self-center mb-6"
                checked={vibrant}
                onChange={(event) => setVibrant(event.currentTarget.checked)}
                label="Vibrant (only images)"
              ></Checkbox> */}
            </InputWrapper>

            <Prism language="json">{shareXConfig}</Prism>
          </div>
        </div>
        <footer className="place-self-center mb-4 font-bold">
          Copyright &copy; Flux Industries
        </footer>
      </div>
    </div>
  );
}

export default App;
